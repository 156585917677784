import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { globalHistory as history } from '@reach/router';
import { navigate } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Grid,
  Typography,
} from '@material-ui/core';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import YouTubeIcon from '@material-ui/icons/YouTube';
import TwitterIcon from '@material-ui/icons/Twitter';
import { Image } from 'components/atoms';
import logo from '../../../../assets/images/logo.png';
import packageJson from '../../../../../package.json';
import versionInfo from '../../../../version.json';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(6, 0),
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(8, 0),
    },
    background: theme.palette.background.footer,
  },
  footerContainer: {
    maxWidth: theme.layout.contentWidth,
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 8),
    },
  },
  buttonLink: {
    color: theme.palette.text.footer,
    borderColor: theme.palette.text.footer
  },
  link: {
    color: theme.palette.text.footer,
    '&:hover': {
      color: theme.palette.text.footerHover
    }
  },
  logoContainer: {
    width: 120,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      margin: '0 auto',
      paddingBottom: theme.spacing(4)
    },
  },
  logoImage: {
    width: '100%'
  },
  groupTitle: {
    textTransform: 'uppercase',
    color: theme.palette.primary.dark,
    marginBottom: theme.spacing(1),
  },
  menuListContainer: {
    padding: '0 !important',
  },
  menu: {
    display: 'flex',
  },
  menuItem: {
    textAlign: 'center',
    '&:last-child': {
      marginBottom: 0,
    },
  },
  menuGroupItem: {
    paddingTop: 0,
    paddingBottom: theme.spacing(1 / 2),
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  menuGroupTitle: {
    textTransform: 'uppercase',
    color: 'white',
  },
  copy: {
    textAlign: 'center',
    padding: theme.spacing(6, 0),
    color: theme.palette.text.footer,
  }
}));

const Footer = props => {
  const { pages, className, ...rest } = props;
  
  const classes = useStyles();
  
  const handleClick = href => event => {
    const { location } = history;
    event.preventDefault();
    if (href !== location.pathname) { /* Fix for broken animations when on the same page */
      navigate(href);
    }
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.footerContainer}>
        <Grid container>
          <Grid item xs={12} sm={3}>
            <div className={classes.logoContainer}>
              <a
                href="/"
                onClick={handleClick('/')}
                title="Formigio"
                className={classes.link}
              >
                <Image
                  className={classes.logoImage}
                  src={logo}
                  alt="Formigio"
                  lazy={true}
                />
              </a>
            </div>
            <div className={classes.logoContainer}>
              <a
                href="https://www.facebook.com/formigio"
                title="Formigio on Facebook"
                className={classes.link}
                target="_blank"
                rel="noreferrer"
              >
                <FacebookIcon
                  alt="Formigio on Facebook"
                />
              </a>
              <a
                href="https://www.instagram.com/formigioapp/"
                title="Formigio on Instagram"
                className={classes.link}
                target="_blank"
                rel="noreferrer"
              >
                <InstagramIcon
                  alt="Formigio on Instagram"
                />
              </a>
              <a
                href="https://www.youtube.com/channel/UCgZIYJ695vAf-21GVBezleQ"
                title="Formigio on YouTube"
                className={classes.link}
                target="_blank"
                rel="noreferrer"
              >
                <YouTubeIcon
                  alt="Formigio on YouTube"
                />
              </a>
              <a
                href="https://twitter.com/formigioapp"
                title="Formigio on Twitter"
                className={classes.link}
                target="_blank"
                rel="noreferrer"
              >
                <TwitterIcon
                  alt="Formigio on Twitter"
                />
              </a>
            </div>
          </Grid>
          <Grid item xs={12} sm={9} className={classes.menuListContainer}>
            <Grid container spacing={2} alignItems="center">
              {Object.values(pages).map((page, i) => (
                <Grid item xs={12} sm={4}
                  key={page.id}
                  aria-describedby={page.id}
                  className={clsx(
                    classes.menuItem
                  )}
                >
                  {page.id === 'download-page' && (
                    <Button
                      variant="outlined"
                      component="a"
                      href={page.href}
                      onClick={handleClick(page.href)}
                      className={classes.buttonLink}
                    >
                      {page.title}
                    </Button>
                  )}
                  {page.id !== 'download-page' && (
                    <Typography
                      variant="body1"
                      component="a"
                      href={page.href}
                      onClick={handleClick(page.href)}
                      className={classes.link}
                    >
                      {page.title}
                    </Typography>
                  )}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        <Grid>
          <Grid item xs={12} className={classes.copy}>
            <Typography>
              Copyright &copy; 2019-{new Date().getFullYear()}{' - '}
              {packageJson.author.name} - Version {versionInfo.version} - RC: {versionInfo.candidate}
            </Typography>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.object.isRequired,
};

export default Footer;
