import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useMediaQuery, Divider } from '@material-ui/core';
import { Topbar, Footer, Sidebar } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
}));

const Main = ({ children, themeToggler, themeMode }) => {
  const classes = useStyles();

  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const pages = {
    about: {
      title: 'About',
      id: 'about-page',
      href: '/about',
    },
    features: {
      title: 'Features',
      id: 'features',
      href: '/features',
    },
    download: {
      title: 'Start Here',
      id: 'download-page',
      href: '/download',
    },
    pricing: {
      title: 'Pricing',
      id: 'pricing-page',
      href: '/pricing',
    },
    login: {
      title: 'Login',
      id: 'login',
      href: 'https://app.formigio.com/',
    }
  };
  
  const footerPages = {
    login: {
      title: 'Login',
      id: 'login',
      href: 'https://app.formigio.com/',
    },
    signup: {
      title: 'Sign Up',
      id: 'signup',
      href: 'https://app.formigio.com/',
    },
    download: {
      title: 'Start Here',
      id: 'download-page',
      href: '/download',
    },
    pricing: {
      title: 'Pricing',
      id: 'pricing-page',
      href: '/pricing',
    },
    about: {
      title: 'About',
      id: 'about-page',
      href: '/about',
    },
    features: {
      title: 'Features',
      id: 'features',
      href: '/features',
    },
    privacy: {
      title: 'Privacy Policy',
      id: 'privacy',
      href: '/privacy-policy'
    },
    cookies: {
      title: 'Cookie Policy',
      id: 'cookies',
      href: '/cookie-policy'
    },
    terms: {
      title: 'Terms & Conditions',
      id: 'terms',
      href: '/company-terms'
    }
  };

  const [openSidebar, setOpenSidebar] = useState(false);

  const handleSidebarOpen = () => {
    setOpenSidebar(true);
  };

  const handleSidebarClose = () => {
    setOpenSidebar(false);
  };

  const open = isMd ? false : openSidebar;

  return (
    <div
      className={clsx({
        [classes.root]: true,
      })}
    >
      <Topbar onSidebarOpen={handleSidebarOpen} pages={pages} themeMode={themeMode} themeToggler={themeToggler} />
      <Sidebar
        onClose={handleSidebarClose}
        open={open}
        variant="temporary"
        pages={pages}
      />
      <main>
        <Divider />
        {children}
      </main>
      <Footer pages={footerPages} />
    </div>
  );
};

Main.propTypes = {
  children: PropTypes.node,
  themeToggler: PropTypes.func.isRequired,
  themeMode: PropTypes.string.isRequired,
};

export default Main;
