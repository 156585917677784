import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { globalHistory as history } from '@reach/router';
import { navigate } from 'gatsby';
import { makeStyles } from '@material-ui/core/styles';
import {
  Toolbar,
  Hidden,
  List,
  ListItem,
  Typography,
  IconButton,
  Button,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { Image, DarkModeToggler } from 'components/atoms';
import logo from 'assets/images/logo.png'

const useStyles = makeStyles(theme => ({
  flexGrow: {
    flexGrow: 1,
  },
  navigationContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  toolbar: {
    zIndex: 999,
    maxWidth: theme.layout.contentWidth,
    width: '100%',
    margin: '0 auto',
    padding: theme.spacing(0, 2),
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(0, 8),
    },
  },
  navLink: {
    '&:hover': {
      color: theme.palette.secondary.main,
    },
  },
  listItem: {
    cursor: 'pointer',
    '&:hover > .menu-item, &:hover svg': {
      color: theme.palette.secondary.dark,
    },
    '&:last-child': {
      paddingRight: 0,
    }
  },
  listItemActive: {
    '&> .menu-item': {
      color: theme.palette.secondary.dark,
    },
  },
  listItemText: {
    flex: '0 0 auto',
    marginRight: theme.spacing(2),
    whiteSpace: 'nowrap',
  },
  listItemButton: {
    whiteSpace: 'nowrap',
  },
  iconButton: {
    marginLeft: theme.spacing(2),
    padding: 0,
    '&:hover': {
      background: 'transparent',
    },
  },
  link: {
    display: 'block'
  },
  logoContainer: {
    width: 120,
    paddingTop: 10,
    paddingBottom: 10
  },
  logoImage: {
    width: '100%'
  },
  menu: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  menuItem: {
    marginRight: theme.spacing(5),
    '&:last-child': {
      marginRight: 0,
    },
  }
}));

const Topbar = ({ themeMode, themeToggler, onSidebarOpen, pages, className, ...rest }) => {
  const classes = useStyles();

  const handleClick = href => event => {
    const { location } = history;
    event.preventDefault();
    if (href !== location.pathname) { /* Fix for broken animations when on the same page */
      navigate(href);
    }
  };
  
  return (
    <Toolbar disableGutters className={classes.toolbar} {...rest}>
      <div className={classes.logoContainer}>
        <a
          href="/"
          title="Formigio"
          onClick={handleClick('/')}
          className={classes.link}
        >
          <Image
            className={classes.logoImage}
            src={logo}
            alt="Formigio"
            lazy={false}
          />
        </a>
      </div>
      <div className={classes.flexGrow} />
      <Hidden smDown>
        <List disablePadding className={classes.navigationContainer}>
          {Object.values(pages).filter(el => el.id !== 'download-page').map((page, i) => (
            <div key={page.id}>
              <ListItem
                aria-describedby={page.id}
                className={clsx(
                  classes.listItem
                )}
              >
                <Typography
                  variant="body1"
                  component="a"
                  color="textPrimary"
                  onClick={handleClick(page.href)}
                  href={page.href}
                  className={clsx(classes.listItemText, 'menu-item')}
                >
                  {page.title}
                </Typography>
              </ListItem>
            </div>
          ))}
          <ListItem className={classes.listItem}>
            <Button
              variant="contained"
              color="secondary"
              component="a"
              href={pages.download.href}
              onClick={handleClick(pages.download.href)}
              className={classes.listItemButton}
            >
              {pages.download.title}
            </Button>
          </ListItem>
          <ListItem className={classes.listItem}>
            <DarkModeToggler themeMode={themeMode} onClick={() => themeToggler()} />
          </ListItem>
        </List>
      </Hidden>
      <Hidden mdUp>
        <DarkModeToggler themeMode={themeMode} onClick={() => themeToggler()} />
        <IconButton
          className={classes.iconButton}
          onClick={onSidebarOpen}
          aria-label="Menu"
          color="secondary"
        >
          <MenuIcon />
        </IconButton>
      </Hidden>
    </Toolbar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.object.isRequired,
  themeToggler: PropTypes.func.isRequired,
  themeMode: PropTypes.string.isRequired,
};

export default Topbar;
